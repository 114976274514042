import * as Sentry from '@sentry/react';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import { Integrations } from '@sentry/tracing';
import NProgress from 'nprogress';
import Router from 'next/router';
import Axios from 'axios';

Axios.defaults.baseURL = process.env.API_BASE_URL;

const showProgressBar = delay => {
  const timer = setTimeout(() => NProgress.start(), delay);
  Router.events.on('routeChangeComplete', () => stopProgress(timer));
  Router.events.on('routeChangeError', () => stopProgress(timer));
};

const stopProgress = timer => {
  clearTimeout(timer);
  NProgress.done();
};

Router.events.on('routeChangeStart', () => showProgressBar(300));

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const Screen = ({ Component, pageProps }) => (
  <ThemeProvider>
    <CSSReset />
    <Component {...pageProps} />
  </ThemeProvider>
);

export default Screen;
